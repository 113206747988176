<template>
    <div class="newStabilimento">
        <v-toolbar color="secondary">
            <v-toolbar-title class="white--text">
                Nuovo Stabilimento
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn small icon @click="$emit('refreshStabilimenti')" color="white" class="ml-3 mr-1">
                <v-icon small>fas fa-times</v-icon>
            </v-btn>
        </v-toolbar>
        <v-card outlined>
            <v-form v-model="formValid" ref="form_data" lazy-formValidation>
                <v-container>
                    <v-layout column>
                        <v-text-field autocomplete="off"
                                      label="Indirizzo"
                                      v-model="indirizzo"
                                      required
                                      :rules="requiredRules" />
                        <v-select :items="['Semestrale', 'Annuale']"
                                  v-model="frequenza_controlli"
                                  required
                                  :rules="requiredRules"
                                  label="Frequenza Controlli"
                                  outlined
                                  hide-details=""
                                  class="mb-3" />
                        <v-textarea autocomplete="off"
                                    label="Note"
                                    hide-details="true"
                                    v-model="note"
                                    outlined
                                    auto-grow
                                    rows="1" />
                        <v-file-input accept="image/jpeg,image/png,image/bmp,image/jpg,image/*"
                                      label="Mappa"
                                      v-model="mappa"
                                      prepend-inner-icon="fas fa-map"
                                      prepend-icon=""
                                      truncate-length="25" />
                        <v-combobox v-model="reparti"
                                    :hide-no-data="false"
                                    hide-selected
                                    label="Reparti"
                                    multiple
                                    required
                                    persistent-hint
                                    small-chips />
                    </v-layout>
                </v-container>
            </v-form>
            <v-card-actions>
                <v-container grid-list-sm fluid>
                    <v-row>
                        <v-col cols="12" md="4" offset-md="4" sm="12" class="pt-0">
                            <v-btn color="primary" width="100%" @click="submit" :disabled="!formValid || loading">Aggiungi</v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
import apiStabilimenti from '@/js/pages/stabilimenti';
import { requiredRules } from '@/js/validationRules';

export default {
    name: 'newStabilimento',
    props: ['id_cliente'],
    data() {
        return {
            token: this.$cookies.get('token'),
            indirizzo: '',
            note: '',
            frequenza_controlli: '',
            mappa: undefined,
            formValid: false,
            requiredRules: requiredRules,
            loading: false,
            search: null,
            reparti: []
        }
    },
    methods: {
        async submit(){
            this.loading = true;
            if(
                this.$refs.form_data.validate() &&
                this.formValid
            ){
                let data = {
                    id_cliente: this.id_cliente,
                    indirizzo: this.indirizzo,
                    frequenza_controlli: this.frequenza_controlli,
                    note: this.note,
                    reparti: this.reparti
                };
                let id_stabilimento = await apiStabilimenti.insertNew(this.token, data);
                let file = await this.sendFile(id_stabilimento);
                data._id = id_stabilimento;
                data.file = file;
                this.$emit('refreshStabilimenti', data);
            }
            this.loading = false;
        },
        async sendFile(_id) {
            if(this.mappa) {
                let formData = new FormData();
                formData.append("mappa", this.mappa);
                return await apiStabilimenti.sendFile(this.token, _id, formData);
            } else return false;
        }
    }
}
</script>
